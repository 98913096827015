import { SvgProps, DEFAULTS } from './iconConfig'

const CopyIcon = ({ size = DEFAULTS.size }: SvgProps) => (
    <svg
        width={size}
        height={size}
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 64 80"
        enableBackground="new 0 0 64 64"
    >
        <path d="M40.648,14c-0.563-1.178-1.758-2-3.148-2h-17c-1.93,0-3.5,1.57-3.5,3.5v28c0,1.488,0.938,2.754,2.25,3.259V20.5  c0-3.584,2.916-6.5,6.5-6.5H40.648z" />
        <path d="M43.5,17H25.75c-1.93,0-3.5,1.57-3.5,3.5v28c0,1.93,1.57,3.5,3.5,3.5H43.5c1.93,0,3.5-1.57,3.5-3.5v-28  C47,18.57,45.43,17,43.5,17z" />
        <path d="M56.781,0H7.218C3.237,0,0,3.239,0,7.219v49.562C0,60.762,3.237,64,7.218,64h49.563C60.762,64,64,60.762,64,56.781V7.218  C64,3.238,60.762,0,56.781,0z M50,48.5c0,3.584-2.916,6.5-6.5,6.5H25.75c-3.103,0-5.701-2.188-6.344-5.1  C16.342,49.378,14,46.711,14,43.5v-28c0-3.584,2.916-6.5,6.5-6.5h17c3.072,0,5.648,2.146,6.322,5.017  C47.256,14.186,50,17.025,50,20.5V48.5z" />
    </svg>
)

export default CopyIcon
