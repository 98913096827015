import { SvgProps, DEFAULTS } from './iconConfig'

const GithubIcon = ({ size = DEFAULTS.size }: SvgProps) => (
    <svg
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
    >
        <path d="M19.7.5H4.3C2.2.5.5 2.2.5 4.3v15.4c0 2.1 1.7 3.8 3.8 3.8h15.4c2.1 0 3.8-1.7 3.8-3.8V4.3c0-2.1-1.7-3.8-3.8-3.8zm-4.9 20.2c-.5.1-.7 0-.9-.2-.2-.2-.3-.4-.3-.7v-.5-1.8c0-.8-.3-1.1-.4-1.2-.1-.1-.2-.3-.1-.5s.2-.3.4-.3c1.8-.2 3.4-.8 3.4-3.7 0-.8-.2-1.4-.7-1.9-.2-.2-.2-.4-.2-.6.1-.2.3-.8 0-1.6-.3 0-.8.2-1.7.8-.1.1-.3.1-.4.1-.6-.2-1.3-.3-2-.3s-1.4.1-2 .3c-.1 0-.3 0-.4-.1-.7-.5-1.3-.7-1.6-.7-.2.8 0 1.4 0 1.6.1.1.1.3 0 .4-.5.5-.7 1.2-.7 1.9 0 2.9 1.7 3.5 3.4 3.7.3 0 .5.3.4.5 0 .3-.3.4-.5.4-1.8-.2-4.3-.9-4.3-4.6 0-.9.3-1.7.8-2.4-.2-.4-.3-1.2.1-2.2l.3-.3c.2-.1 1-.2 2.5.8.6-.2 1.3-.2 2-.2s1.4.1 2 .2c1.6-1 2.4-.9 2.5-.8.1 0 .2.1.3.3.4 1 .3 1.8.2 2.3.5.7.8 1.5.8 2.4 0 3.2-1.8 4.2-3.5 4.5.1.3.2.8.2 1.2v2.2c3.2-1.1 5.3-4.1 5.3-7.5 0-4.4-3.5-7.9-7.9-7.9s-7.9 3.6-7.9 7.9c0 3.4 2.2 6.5 5.4 7.5-.1-.1-.1-1.1-.1-1.8h-.4c-1.5 0-2.3-1-2.5-1.6-.1-.3.1-.5.3-.6.3-.1.5.1.6.3 0 .1.5 1.2 2.3.8.3-.1.5.1.6.4v2.5c0 .3-.1.5-.3.7-.1.1-.3.2-.6.2H9c-3.7-1.2-6.1-4.6-6.1-8.5 0-4.9 4-8.9 8.9-8.9s8.9 4 8.9 8.9c.2 4-2.3 7.3-5.9 8.6z" />
    </svg>
)

export default GithubIcon
